import { SchemaType } from '~/utils/conform/conform-helper.ts';

import { z } from 'zod';

declare global {
  interface Window {
    ENV: PublicEnv;
  }
}

export const publicEnvSchema = z.object({
  PUBLIC_SENTRY_DSN: z.string(),
  IS_PRODUCTION: z.boolean(),
});

export const PublicEnv = ({ publicEnv }: { publicEnv: PublicEnv }) => {
  return (
    <script
      dangerouslySetInnerHTML={{ __html: `window.ENV = ${JSON.stringify(publicEnv)}` }}></script>
  );
};

export function getPublicEnv<T extends keyof PublicEnv>(key: T) {
  if (typeof window !== 'undefined' && !window.ENV) {
    throw new Error(`Missing <PublicEnv/> component`);
  }
  if (typeof window === 'undefined') {
    throw new Error('Window unavailable');
  }
  return window.ENV[key];
}

export type PublicEnv = SchemaType<typeof publicEnvSchema>;
